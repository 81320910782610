/* @flow */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import AddEditComponent from '../../components/SharedComponents/AddEditComponent/AddEditComponent';
import SaveErrorDialog from '../Maintenance/SaveErrorDialog';
import PlansStepper from '../../components/PlansStepper/PlansStepper';
import { getPermissionValue, getLocalStorageValue, isEmpty } from '../../helper-classes/utility-functions';
import { saveDistributionList, addServicePlan, editServicePlan, deleteServicePlan, addServicePlanWithGlobalId } from '../../containers/Maintenance/helper-classes/common-services';
import analytics from '../../analytics/index';

export type Props = {
    fetchServicePlans: Function,
    windowTitle: string,
    isShown: boolean,
    closeAddEdit: Function,
    selectedPlan: Object,
    editMode: boolean,
};

export type State = {
    show: boolean,
    errorDialogOpen: boolean,
    deleteDialogOpen: boolean,
    showingButtons: Array<string>,
    activeStep: number,
    recipients: Array<Object>,
    servicePlanId: string | any,
    servicePlanName: string,
    assetGroups: Array<any>,
    assetIds: Array<string>,
    assetTags: Array<any>,
    autoResetStatus: boolean | any,
    description: string,
    distributionLists: Array<string>,
    distanceInterval: string,
    firstServiceOdometer: string,
    timePeriodInterval: number,
    firstServiceDate: string,
    engineHoursInterval: string,
    firstServiceEngineHours: number,
    assetCriteriaType: string,
    distanceChecked: boolean,
    engineHoursChecked: boolean,
    timePeriodChecked: boolean,
    errorTitle: string,
    errorMessage: string,
    originalDistanceInterval: string,
}

const styles = () => ({
    search: {
        position: 'relative',
        marginLeft: 0,
        width: '95%',
        maxWidth: 350,
    },
    centerText: { textAlign: 'center' },
    plansDrop: {
        position: 'relative',
        margin: 0,
        width: '95%',
        maxWidth: 350,
    },
    firstCol: { borderRight: '1px solid #979797' },
    container: { display: 'flex', flexWrap: 'wrap' },
    searchIcon: {
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 0,
        color: '#767676',
    },
    helpIconDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#767676',
    },
    helpIcon: {
        fontSize: 20,
        alignItems: 'center',
        justifyContent: 'center',
        color: '#767676',
    },
    main: { margin: 0 },
    items: { marginBottom: 12 },
    textField: {
        maxWidth: 350,
        width: '85%',
        paddingTop: 0,
        marginBottom: 20,
        marginTop: 0,
    },
    title: { fontSize: 20, whiteSpace: 'nowrap' },
    subtitle: { fontSize: 15, whiteSpace: 'nowrap' },
});

type Ref = {current: any | HTMLDivElement};
class AddServicePlans extends React.Component<Props, State> {
    dpRef: Ref;
    initialState: Object;

    constructor(props: Props) {
        super(props);
        this.initialState = {
            assetGroups: [],
            assetIds: [],
            assetTags: [],
            servicePlanId: '',
            distanceInterval: '',
            firstServiceOdometer: '',
            timePeriodInterval: 0,
            firstServiceDate: '',
            engineHoursInterval: '',
            firstServiceEngineHours: 0,
            activeStep: 0,
            originalDistanceInterval: '',
        };

        this.state = {
            show: props.isShown,
            recipients: [],
            description: '',
            servicePlanName: '',
            distributionLists: [],
            autoResetStatus: false,
            errorDialogOpen: false,
            deleteDialogOpen: false,
            showingButtons: ['cancel', 'next'],
            assetCriteriaType: 'all-assets',
            distanceChecked: false,
            engineHoursChecked: false,
            timePeriodChecked: false,
            errorTitle: '',
            errorMessage: '',
            ...this.initialState,
        };

        // create datepicker ref so you can open the calendar via button
        this.dpRef = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this.correctButtons(0, this.props.editMode);
        document.addEventListener('keydown', (evt: any) => {
            const e = evt || window.event;
            if (this.state.show && e.keyCode === 27) this.handleCancelClick();
        });
        this.getDataFromSelectedPlan(this.props.selectedPlan, this.props.editMode);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        const { selectedPlan, isShown } = nextprops;
        if (this.state.show !== isShown) this.setState({ show: isShown });
        if (selectedPlan && Object.keys(selectedPlan).length > 0) {
            this.getDataFromSelectedPlan(selectedPlan, nextprops.editMode);
        }
    }

    getDataFromSelectedPlan = (selectedPlan: Object, editMode: boolean) => {
        const newStates = { ...this.state };
        Object.keys(selectedPlan).forEach((i) => {
            let defaultReturnVal = false;
            if (['id', 'distanceInterval', 'firstServiceOdometer', 'firstServiceDate', 'engineHoursInterval'].includes(i)) {
                // for string type state only
                defaultReturnVal = '';
            } else if (['timePeriodInterval', 'firstServiceEngineHours'].includes(i)) {
                // for number type state only
                defaultReturnVal = 0;
            }
            if (editMode) newStates[(i === 'id') ? 'servicePlanId' : i] = selectedPlan[i] || defaultReturnVal;
        });
        this.setState({ ...newStates });
    }

    dateToYMD = (date) => {
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`;
    }

    getServicePlan = (assetId: string, servicePlanId: string) =>
        this.props.fetchServicePlans(0, 50, assetId, servicePlanId)

    onPlanChanged = (e) => {
        let dataToUpdate = {
            servicePlanName: !isEmpty(e.servicePlanName) ? e.servicePlanName.trim() : '',
            description: e.servicePlanDescription,
        };

        if (e.assetCriteria && !this.props.editMode) {
            dataToUpdate = {
                ...dataToUpdate,
                ...{
                    assetIds: e.assetCriteria.assets.map(asset => asset.id),
                    assetGroups: e.assetCriteria.groups,
                    assetTags: e.assetCriteria.tags,
                    assetCriteriaType: e.assetCriteria.type,
                },
            };
        }

        const recipients = [];
        if (e.recipientsList && e.recipientsList.length) {
            e.recipientsList.forEach(recipient => recipients.push(recipient));
        }
        dataToUpdate = { ...dataToUpdate, ...{ recipients } };

        if (e.serviceInterval) {
            dataToUpdate = {
                ...dataToUpdate,
                ...{ autoResetStatus: e.serviceInterval.autoResetChecked },
            };

            if (e.serviceInterval.distanceChecked) {
                dataToUpdate = {
                    ...dataToUpdate,
                    ...{
                        distanceChecked: true,
                        distanceInterval: e.serviceInterval.distanceInterval,
                        firstServiceOdometer: e.serviceInterval.firstServiceOdometer,
                    },
                };
            } else {
                dataToUpdate = {
                    ...dataToUpdate,
                    ...{
                        distanceChecked: false,
                        distanceInterval: '',
                        firstServiceOdometer: '',
                    },
                };
            }

            if (e.serviceInterval.engineHoursChecked) {
                dataToUpdate = {
                    ...dataToUpdate,
                    ...{
                        engineHoursChecked: true,
                        engineHoursInterval: e.serviceInterval.engineHoursInterval,
                        firstServiceEngineHours: e.serviceInterval.firstServiceEngineHours,
                    },
                };
            } else {
                dataToUpdate = {
                    ...dataToUpdate,
                    ...{
                        engineHoursChecked: false,
                        engineHoursInterval: '',
                        firstServiceEngineHours: 0,
                    },
                };
            }

            if (e.serviceInterval.timePeriodChecked) {
                dataToUpdate = {
                    ...dataToUpdate,
                    ...{
                        timePeriodChecked: true,
                        timePeriodInterval: e.serviceInterval.timePeriodInterval,
                        firstServiceDate: e.serviceInterval.firstServiceDate,
                    },
                };
            } else {
                dataToUpdate = {
                    ...dataToUpdate,
                    ...{
                        timePeriodChecked: false,
                        timePeriodInterval: 0,
                        firstServiceDate: '',
                    },
                };
            }
        }
        this.setState(dataToUpdate);
    }

    handleCancelClick = () => {
        this.setState({ ...this.initialState });
        this.props.closeAddEdit();
    }

    handleChange = prop => event => this.setState({ [prop]: event.target.value });

    handleErrorClose = () => this.setState({ errorDialogOpen: false });

    handleErrorOpen = (message, title) => this.setState({
        errorDialogOpen: true,
        errorMessage: message,
        errorTitle: title,
    });

    handleDeleteClose = () => this.setState({ deleteDialogOpen: false });

    handleDeleteOpen = () => {
        this.setState({ deleteDialogOpen: true });
    }

    handleNextClick = () => {
        const { activeStep } = this.state;
        if (activeStep < 2) {
            this.setState({ activeStep: activeStep + 1 });
            this.correctButtons(activeStep + 1, this.props.editMode);
        }
    }

    handleBackClick = () => {
        const { activeStep } = this.state;
        if (activeStep > 0) {
            this.setState({ activeStep: activeStep - 1 });
            this.correctButtons(activeStep - 1, this.props.editMode);
        }
    }

    correctButtons = (step, editMode) => {
        let showingButtons = ['cancel', 'back', 'next'];
        if (getPermissionValue('Maintenance') === 'Modify') {
            if (step <= 0) {
                showingButtons = editMode ? ['cancel', 'next', 'delete'] : ['cancel', 'next'];
            } else if (step >= 2) {
                showingButtons = editMode ? ['cancel', 'back', 'delete', 'save'] : ['cancel', 'back', 'save'];
            } else {
                showingButtons = editMode ? ['cancel', 'back', 'next', 'delete'] : ['cancel', 'back', 'next'];
            }
        } else if (step <= 0) showingButtons = ['cancel', 'next'];
        else if (step >= 2) showingButtons = ['cancel', 'back'];

        this.setState({ showingButtons });
    }

    getAPIPayload = (dataToOverride = {}) => ({
        id: this.state.servicePlanId,
        assetGroups: this.state.assetGroups,
        assetIds: this.state.assetIds,
        assetTags: this.state.assetTags,
        autoResetStatus: this.state.autoResetStatus,
        description: this.state.description,
        distanceInterval: this.state.distanceInterval,
        distributionLists: this.state.distributionLists,
        engineHoursInterval: this.state.engineHoursInterval,
        firstServiceDate: this.state.firstServiceDate,
        firstServiceEngineHours: this.state.firstServiceEngineHours,
        firstServiceOdometer: this.state.firstServiceOdometer,
        name: this.state.servicePlanName,
        timePeriodInterval: this.state.timePeriodInterval,
        ...dataToOverride,
    });

    deleteHandler = () => {
        deleteServicePlan(this.getAPIPayload()).then(() => {
            document.dispatchEvent(new Event('updateOverdueStatuses'));
            this.handleCancelClick();
            window.location.reload();
        });
        analytics.track('DELETE_SERVICE_PLAN', { feature: 'Maintenance' });
        this.handleDeleteClose();
    }

    saveHandler = () => {
        let content = {
            accountId: getLocalStorageValue('currentAccountUserId'),
            recipients: JSON.stringify(this.state.recipients),
            name: this.state.servicePlanName,
        };

        const { originalDistanceInterval } = this.state;
        saveDistributionList(content).then((response) => {
            if (response.data && response.data.distributionListId) {
                let { assetTags, assetGroups: assetGroupIds, assetIds } = this.state;
                const { assetCriteriaType } = this.state;

                if (this.props.selectedPlan && this.props.editMode) {
                    ({ assetTags, assetIds, assetGroupIds } = this.props.selectedPlan);
                } else {
                    assetTags = (assetTags.length > 0) ? assetTags.map(t => t.tag) : [];
                    assetGroupIds = (assetGroupIds.length > 0) ? assetGroupIds.map(g => g.id) : [];
                }

                content = this.getAPIPayload({
                    assetSubscriptionType: 'DIRECT',
                    assetTags,
                    assetGroupIds,
                    assetIds,
                    distributionLists: [response.data.distributionListId],
                });
                this.setState({ distributionLists: content.distributionLists });

                if (this.props.editMode) {
                    if (originalDistanceInterval) {
                        content.distanceInterval = originalDistanceInterval;
                    }
                    editServicePlan(content).then((res) => {
                        if (res && res.id) {
                            document.dispatchEvent(new Event('updateOverdueCount'));
                            this.handleCancelClick();
                        } else this.handleErrorOpen(res.msg || res.message || res.error || 'Error updating service plan. Please try again.');
                    });
                    const params = {
                        feature: 'Maintenance',
                        servicePlanName: this.state.servicePlanName,
                        autoResetService: this.state.autoResetStatus,
                    };
                    analytics.track('EDIT_SERVICE_PLAN', params);
                } else if (assetCriteriaType && assetCriteriaType === 'custom') {
                    addServicePlanWithGlobalId(content).then((res) => {
                        if (res && res.id) {
                            document.dispatchEvent(new Event('updateOverdueCount'));
                            this.handleCancelClick();
                            const params = {
                                feature: 'Maintenance',
                                assetCriteria: 'Custom',
                                servicePlanName: this.state.servicePlanName,
                                distance: this.state.distanceChecked,
                                timePeriod: this.state.timePeriodChecked,
                                engineHours: this.state.engineHoursChecked,
                                autoResetService: this.state.autoResetStatus,
                            };
                            analytics.track('ADD_SERVICE_PLAN', params);
                        } else this.handleErrorOpen(res.msg || res.message || res.error || 'Error creating service plan. Please try again.');
                    });
                } else {
                    addServicePlan(content).then((res) => {
                        if (res.success) {
                            document.dispatchEvent(new Event('updateOverdueCount'));
                            this.handleCancelClick();
                            const params = {
                                feature: 'Maintenance',
                                assetCriteria: this.state.assetCriteriaType === 'groups-tags' ? 'Select Groups/Tags' : 'All Assets',
                                servicePlanName: this.state.servicePlanName,
                                distance: this.state.distanceChecked,
                                timePeriod: this.state.timePeriodChecked,
                                engineHours: this.state.engineHoursChecked,
                                autoResetService: this.state.autoResetStatus,
                            };
                            analytics.track('ADD_SERVICE_PLAN', params);
                        } else this.handleErrorOpen(res.msg || res.message || res.error || 'Error creating service plan. Please try again.');
                    });
                }
            } else if (!response.success && (response.message || response.msg || response.error)) {
                this.handleErrorOpen(response.message || response.msg || response.error);
            }
        });
    }

    setApiDistanceInterval = (distanceInterval: string) => {
        if (distanceInterval) this.setState({ originalDistanceInterval: distanceInterval });
    }

    render() {
        let disableNext = false;
        const {
            activeStep,
            distanceInterval,
            distanceChecked,
            engineHoursInterval,
            engineHoursChecked,
            timePeriodInterval,
            timePeriodChecked,
            assetCriteriaType,
            assetIds,
            assetTags,
            assetGroups,
            servicePlanName,
        } = this.state;

        if (activeStep === 0) {
            if (assetCriteriaType === 'custom') {
                disableNext = (!assetIds || assetIds.length === 0);
            }
            if (assetCriteriaType === 'groups-tags') {
                disableNext = (!assetTags || assetTags.length === 0)
                    && (!assetGroups || assetGroups.length === 0);
            }
        } else if (activeStep === 1) {
            disableNext = !servicePlanName ||
                (!distanceInterval && !timePeriodInterval && !engineHoursInterval) ||
                (distanceChecked && !distanceInterval) ||
                (timePeriodChecked && !timePeriodInterval) ||
                (engineHoursChecked && !engineHoursInterval);
        }

        return (
            <React.Fragment>
                <SaveErrorDialog
                    open={this.state.deleteDialogOpen}
                    handleClose={this.handleDeleteClose}
                    dialogTitle="Confirm Service Plan Delete"
                    dialogMessage="Are you sure you want to delete this Service Plan?"
                    dialogBtn1Text="YES"
                    dialogBtn1Click={this.deleteHandler}
                    dialogBtn2Text="NO"
                    dialogBtn2Click={this.handleDeleteClose}
                />
                <SaveErrorDialog
                    open={this.state.errorDialogOpen}
                    handleClose={this.handleErrorClose}
                    dialogTitle={this.state.errorTitle || 'Error Occurred'}
                    dialogMessage={this.state.errorMessage || 'Please try again'}
                    dialogBtn1Text="Ok"
                    dialogBtn1Click={this.handleErrorClose}
                />
                <AddEditComponent
                    windowTitle={this.props.windowTitle}
                    cancelClick={this.handleCancelClick}
                    saveClick={this.saveHandler}
                    deleteClick={this.handleDeleteOpen}
                    nextClick={this.handleNextClick}
                    nextDisabled={disableNext}
                    backClick={this.handleBackClick}
                    show={this.state.show}
                    saveDisabled={!this.state.servicePlanName}
                    showingButtons={this.state.showingButtons}
                >
                    <PlansStepper
                        activeStep={this.state.activeStep}
                        onPlanChanged={this.onPlanChanged}
                        selectedPlan={this.props.selectedPlan}
                        editMode={this.props.editMode}
                        setApiDistanceInterval={this.setApiDistanceInterval}
                    />
                </AddEditComponent>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AddServicePlans);
